import styled from "styled-components";

export const RazorpayWrapper = styled.div``;
export const Button = styled.button`
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  background: blue;
  color: white;
  border: none;
`;
